<template>
  <div class="pa-0" v-if="localProduct && localProduct.id && localProduct.name">
    <v-row>
      <v-col cols="12" sm="12" md="7">
        <small
          >{{ $t('Common.add_picture') }} for {{ localProduct.name }}</small
        >
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="8">
        <media-uploader
          @uploadMedia="uploadMedia"
          uploadType="media"
          :fullWidth="true"
          :hasDescription="false"
          :dense="true"
        />
      </v-col>
    </v-row>

    <v-row no-gutters>
      <v-col cols="12" sm="6" v-if="false && dishList && dishList.length > 1">
        <suggested-items
          :dishList="dishList"
          :dish="localProduct"
          type="suggested_products"
          :hint="$t('Common.suggest_dishitem_hint')"
        />
      </v-col>

      <v-col cols="12" sm="6" v-if="false && menus && menus.length > 0">
        <suggested-items
          :menus="menus"
          :dish="localProduct"
          type="suggested_menus"
          :hint="$t('Common.suggest_menu_hint')"
        />
      </v-col>

      <v-col cols="12" sm="6" v-if="false">
        <v-combobox
          v-model="selectedTags"
          :items="localProduct.tags"
          chips
          :delimiters="[',', ';']"
          clearable
          multiple
          :hint="$t('Common.tag_hint')"
          persistent-hint
          :label="$t('Common.add_tags')"
          color="takein"
          class="mb-4"
        >
          <template slot="selection" slot-scope="data">
            <v-chip :selected="data.selected" close @input="remove(data.item)">
              <strong>{{ data.item }}</strong
              >&nbsp;
            </v-chip>
          </template>
        </v-combobox>
      </v-col>

      <v-col cols="12" sm="6" v-if="modifiers && modifiers.length > 0">
        <v-combobox
          v-model="selectedModifiers"
          :items="modifiers"
          :label="$t('Common.add_modifiers')"
          multiple
          :hint="$t('Common.product_modifiers_hint')"
          persistent-hint
          color="takein"
          item-text="name"
          item-value="id"
          chips
          return-object
        ></v-combobox>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="px-1">
        <v-sheet class="mx-auto">
          <v-slide-group
            class="pa-2"
            show-arrows
            v-if="localProduct.photos && localProduct.photos.length > 0"
          >
            <v-slide-item
              v-for="photo in localProduct.photos"
              :key="photo.imageUrl"
            >
              <v-card
                class="ma-2"
                :width="
                  $vuetify.breakpoint.smAndDown
                    ? imageRatio.mobile.width
                    : imageRatio.desktop.width
                "
                :height="
                  $vuetify.breakpoint.smAndDown
                    ? imageRatio.mobile.height
                    : imageRatio.desktop.height
                "
              >
                <v-img
                  :src="photo.imageUrl"
                  :max-height="
                    $vuetify.breakpoint.smAndDown
                      ? imageRatio.mobile.height
                      : imageRatio.desktop.height
                  "
                  class="dish-image"
                ></v-img>
              </v-card>
            </v-slide-item>
          </v-slide-group>
        </v-sheet>
      </v-col>
    </v-row>

    <!-- image upload success dialogue -->
    <v-dialog v-model="uploadProgress" width="700px">
      <v-card>
        <v-card-title class="mx-2">
          <v-icon large color="success">check</v-icon>
          <h4 class="ml-1 fs-16">
            Image Successfully Uploaded! Please Allow Time for the Photo to
            Appear.
          </h4>
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="uploadProgress = false">Ok</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snackbar"
      :bottom="true"
      :color="snackType"
      :timeout="snackTimeout"
    >
      {{ snackMessage }}
      <v-btn color="white" text @click="snackbar = false">Close</v-btn>
    </v-snackbar>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  props: ['product', 'menus', 'uid', 'dishList'],
  components: {
    MediaUploader: () =>
      import('fod-core/src/components/common/MediaUploader.vue'),
    SuggestedItems: () => import('../../../cook/SuggestedItems.vue'),
    DishPhotos: () => import('../../../cook/DishPhotos.vue')
  },
  data() {
    return {
      snackTimeout: 2500,
      snackbar: false,
      snackMessage: '',
      snackType: 'success',
      imageRatio: {
        mobile: {
          width: 160,
          height: 90 // 160 / 16 * 9
        },
        desktop: {
          width: 240,
          height: 135
        }
      },
      selectedTags: [],
      selectedModifiers: [],
      modifiers: [],
      uploadProgress: false,
      localProduct: null,
      photos: []
    }
  },
  computed: {
    ...mapGetters({
      suggestedProducts: 'suggestedProducts',
      suggestedMenus: 'suggestedMenus'
    })
  },
  watch: {
    product(newval) {
      this.localProduct = newval
    }
  },
  mounted() {
    this.$store.dispatch(
      'updateSuggestedProducts',
      this.product.suggested_products
    )
    this.$store.dispatch('updateSuggestedMenus', this.product.suggested_menus)
    this.$store.dispatch('loadModifiers').then(list => {
      this.modifiers = list
    })
    this.selectedTags = this.product.tags || []
    if (this.product.modifiers) {
      this.selectedModifiers = Object.values(this.product.modifiers)
    }
    this.localProduct = this.product
  },

  methods: {
    onWebPhotoSelected(url) {
      if (url) {
        this.photos.push({ imageUrl: url, type: 'external' })
        this.$store.dispatch('saveDish', {
          food: {
            uid: this.localProduct.uid,
            id: this.localProduct.id,
            photos: this.photos
          }
        })
      }
    },
    // uploadMedia(file, description) {
    //   console.log(file, description, ' click uploadMedia!!!')
    // },
    uploadMedia: function(file, img) {
      this.uploadProgress = true
      this.$store.dispatch('setLoading', true)
      this.$store.dispatch('uploadDishPhoto', {
        id: this.localProduct.id,
        uid: this.localProduct.uid,
        index: Math.random()
          .toString(36)
          .substring(8),
        image: img,
        file: file
      })
      const that = this
      setTimeout(function() {
        that.$store
          .dispatch('loadDishList', { uid: this.uid })
          .then(dishlist => {
            let matched = dishlist.filter(
              dish => dish.id === that.localProduct.id
            )[0]
            that.localProduct = matched
            console.log('New Product:', matched)
          })
      }, 3500)
    },
    save() {
      let modifiers = {}

      if (this.selectedModifiers.length > 0) {
        this.selectedModifiers.forEach(mod => {
          modifiers[mod.id] = mod
        })
      }
      this.localProduct.tags = this.selectedTags
      this.localProduct.modifiers = modifiers

      this.$store.dispatch('saveDish', { food: this.localProduct }).then(id => {
        console.log('Save product:', id)
        this.$store.dispatch('loadDishList', { uid: this.uid }).then(() => {
          // this.snackMessage = 'Product saved successfully.'
          // this.snackType = 'success'
          // this.snackbar = true
          // this.$store.dispatch('setMessage', {
          //   title: 'Success',
          //   body: 'Product saved'
          // })
        })
      })
    }
  }
}
</script>
<style>
.shadow {
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06),
    0px 4px 8px rgba(0, 0, 0, 0.04);
}
.v-slide-group__next,
.v-slide-group__prev {
  min-width: 28px;
}
.dish-image {
  border-radius: 5px;
}
</style>
